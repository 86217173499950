<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col>
        <relatorio-cotas-cliente-list-filtro @search="handleGetProgramacoes" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <cotas-programacao-regiao ref="cotas" v-bind:params="params" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import RelatorioCotasClienteListFiltro from '@/components/patio/relatorioCotasCliente/RelatorioCotasClienteListFiltro.vue'
import CotasProgramacaoRegiao from '@/components/patio/relatorioCotasCliente/Cotas.vue'

import { data } from './_data'

export default {
  name: 'RelatorioCotasCliente',
  provide() {
    return {
      visualizarComprovante: this.visualizarComprovante,
      visualizarComprovanteAgendamento: this.visualizarComprovanteAgendamento,
      visualizarComprovanteLaudo: this.visualizarComprovanteLaudo,
      fecharComprovanteAgendamento: this.fecharComprovanteAgendamento,
    }
  },
  components: {
    RelatorioCotasClienteListFiltro,
    CotasProgramacaoRegiao,
  },
  data,
  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('relatorioCotasCliente', ['params']),
  },
  methods: {
    async handleGetProgramacoes() {
      await this.$refs.cotas.atualizarEstrutura(this.params)
    },
  },
}
</script>
