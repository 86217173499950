<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="programacoesRegiaoEstruturada.filter(semanaAtual)"
      class="border"
      :disable-sort="true"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Cotas Cadastradas</v-toolbar-title>
          <v-divider class="mx-5" inset vertical></v-divider>
          <v-icon
            v-if="canList"
            title="Atualizar"
            medium
            @click="() => atualizarEstrutura()"
            >mdi-refresh</v-icon
          >
          <v-divider class="mx-5" inset vertical></v-divider>
          <v-switch
            v-if="canList"
            v-model="details"
            label="Detalhes"
            class="mt-5"
          ></v-switch>
          <v-divider class="mx-5" inset vertical></v-divider>
          <v-switch
            v-if="canList"
            v-model="peso"
            label="Por Peso"
            class="mt-5"
          ></v-switch>
        </v-toolbar>
      </template>
      <template
        v-slot:item.programacao_fornecedor.programacao_unidade.terminal.trade_name="{
          item,
        }"
      >
        {{
          item.programacao_fornecedor.programacao_unidade.fornecedor.trade_name
        }}<br />
        {{
          item.programacao_fornecedor.programacao_unidade.terminal.trade_name
        }}
      </template>
      <template
        v-slot:item.programacao_fornecedor.programacao_unidade.produto.descricao="{
          item,
        }"
      >
        {{
          item.programacao_fornecedor.programacao_unidade.produto
            ? item.programacao_fornecedor.programacao_unidade.produto.descricao
            : 'Nenhum'
        }}<br />
        {{
          item.programacao_fornecedor.programacao_unidade.sub_produto
            ? item.programacao_fornecedor.programacao_unidade.sub_produto
                .descricao
            : ''
        }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-data-table
          :headers="headersDay"
          :items="item.status"
          :disable-sort="true"
          class="border"
          hide-default-footer
        >
          <template v-slot:body="props"
            ><tbody>
              <tr
                v-for="(item, index) in props.items"
                :key="item.descricao"
                v-show="index == 0 || details"
              >
                <td
                  class="d-block d-sm-table-cell"
                  v-for="field in headersDay.slice(0, 1)"
                  :key="field.value"
                >
                  {{ item[field.value] }}
                </td>
                <td
                  class="d-block d-sm-table-cell"
                  v-for="field in headersDay.slice(1)"
                  :key="field.value"
                >
                  <span v-show="peso == true">
                    {{ item[field.value] ? item[field.value].qt_peso : '0' }}
                  </span>
                  <span v-show="!peso == true">
                    {{
                      item[field.value] ? item[field.value].qt_caminhoes : '0'
                    }}
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </template>
      <template #no-data v-if="!canList">
        <span class="red--text"
          >Você não tem permissão para visualizar as informações desta
          tela</span
        >
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { DAYS, TIME_DAYS } from '@/mixins/days.js'
import { pesquisa } from '@/mixins/relatorioCotas.js'
import { contabilizadores } from '@/mixins/cotas.js'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  name: 'CotasProgramacaoCliente',
  props: ['calendario'],
  mixins: [contabilizadores, pesquisa],
  data() {
    return {
      peso: false,
      details: false,
      headersDay: [
        { text: 'Status', value: 'descricao' },
        { text: 'DOMINGO', value: 'DOMINGO' },
        { text: 'SEGUNDA', value: 'SEGUNDA' },
        { text: 'TERÇA', value: 'TERÇA' },
        { text: 'QUARTA', value: 'QUARTA' },
        { text: 'QUINTA', value: 'QUINTA' },
        { text: 'SEXTA', value: 'SEXTA' },
        { text: 'SÁBADO', value: 'SÁBADO' },
      ],
      headers: [
        { text: 'Cliente', value: 'cliente.business_name' },
        { text: 'Região', value: 'programacao_fornecedor.regiao.sigla' },
        { text: 'Tipo de Frete', value: 'tipo_frete.descricao' },
        {
          text: 'Tipo',
          value:
            'programacao_fornecedor.programacao_unidade.tipo_operacao.descricao',
        },
        {
          text: 'Terminal',
          value:
            'programacao_fornecedor.programacao_unidade.terminal.trade_name',
        },
        {
          text: 'Produto',
          value: 'programacao_fornecedor.programacao_unidade.produto.descricao',
        },
        { text: 'Cotas', value: 'status' },
      ],
      programacoesRegiaoEstruturada: [],
      estrutura_exemplo: {
        programacao_fornecedor: null,
        tipo_frete: null,
        cliente: null,
        pedidos: [],
        status: [
          {
            descricao: 'Cotas Liberadas',
          },
          {
            descricao: 'Agendados',
          },
          {
            descricao: 'Atendidas',
          },
          {
            descricao: 'Disponível',
          },
          {
            descricao: 'Performace',
          },
        ],
      },
    }
  },
  computed: {
    ...mapState('programacao', ['programacoesRegiao']),
    ...mapState('auth', ['user', 'empresaAtual']),
    ...mapState('relatorioCotasCliente', ['params']),
    ...mapState('patio', ['regioes']),
    permission() {
      return Sequences.CotasCliente.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  async created() {
    if (this.canList) {
      this.DAYS = DAYS
      await this.headersDayNow()
    }
  },
  methods: {
    ...mapActions('programacao', ['getProgramacoesRegiao']),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    ...mapActions('patio', [
      'getProgramacoesUnidade',
      'getTipoOperacao',
      'getRegioes',
      'getTiposFrete',
      'getRepresentante',
    ]),
    semanaAtual(value) {
      let desta_semana = false
      let dias = this.headersDay.slice(1, 8)
      dias.every(function (dia) {
        if (
          (value.status[0][dia.text]
            ? value.status[0][dia.text].qt_peso ||
              value.status[0][dia.text].qt_caminhoes
            : 0) > 0
        ) {
          desta_semana = true
          return false
        }
        return true
      })
      return desta_semana
    },
    async atualizarListaProgramacoesRegiao(params = null) {
      try {
        if (params == null) {
          params = this.params
        }
        let filtros = this.pesquisa(params)
        if (!filtros.data_inicio) {
          filtros.data_inicio = new Date(
            new Date(new Date().setHours(0, 0, 0, 0)).setDate(
              new Date().getDate() - new Date().getDay()
            )
          )
            .toISOString()
            .split('T')[0]
        }

        await this.getProgramacoesRegiao(filtros)
      } catch (error) {
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage('Empresa não possui acesso as programações.')
        } else {
          this.errorMessage(error.response.data)
        }
      }
    },
    headersDayNow() {
      let semana = [
        this.DAYS.DOMINGO,
        this.DAYS.SEGUNDA,
        this.DAYS.TERCA,
        this.DAYS.QUARTA,
        this.DAYS.QUINTA,
        this.DAYS.SEXTA,
        this.DAYS.SABADO,
      ]
      let data = new Date()
      let newHeader = []
      semana.forEach((dia) => {
        let diff = data.getUTCDay() - dia
        let nova_data = new Date(data.getTime() - TIME_DAYS(diff))
        let new_data = nova_data.toISOString().split('T')[0].split('-')
        let new_text = new_data.reverse().join('/')
        newHeader.push({
          text: new_text,
          value: new_text,
        })
      })
      this.changeHeadersDay(newHeader)
    },
    async atualizarEstrutura() {
      this.programacoesRegiaoEstruturada = []
      await this.atualizarListaProgramacoesRegiao()
      this.estruturaProgramacoesRegiao()
    },
    estruturaProgramacoesRegiao() {
      let estrutura = []
      let scope = this
      this.programacoesRegiao.forEach((element) => {
        element.programacao_fornecedor.tipos_frete.forEach((elem) => {
          let i = estrutura.findIndex(
            (el) =>
              el.cliente.public_id == element.cliente.public_id &&
              el.programacao_fornecedor.regiao.public_id ==
                element.programacao_fornecedor.regiao.public_id &&
              el.programacao_fornecedor.programacao_unidade.terminal
                .public_id ==
                element.programacao_fornecedor.programacao_unidade.terminal
                  .public_id &&
              (el.programacao_fornecedor.programacao_unidade.produto
                ? el.programacao_fornecedor.programacao_unidade.produto.id
                : el.programacao_fornecedor.programacao_unidade.produto) ==
                (element.programacao_fornecedor.programacao_unidade.produto
                  ? element.programacao_fornecedor.programacao_unidade.produto
                      .id
                  : element.programacao_fornecedor.programacao_unidade
                      .produto) &&
              (el.programacao_fornecedor.programacao_unidade.sub_produto
                ? el.programacao_fornecedor.programacao_unidade.sub_produto.id
                : el.programacao_fornecedor.programacao_unidade.sub_produto) ==
                (element.programacao_fornecedor.programacao_unidade.sub_produto
                  ? element.programacao_fornecedor.programacao_unidade
                      .sub_produto.id
                  : element.programacao_fornecedor.programacao_unidade
                      .sub_produto) &&
              el.programacao_fornecedor.programacao_unidade.fornecedor
                .public_id ==
                element.programacao_fornecedor.programacao_unidade.fornecedor
                  .public_id &&
              el.tipo_frete.id == elem.id
          )
          if (i == -1) {
            // CRIANDO ESTRUTURA
            let nova_estrutura = { ...this.estrutura_exemplo }
            nova_estrutura.programacao_fornecedor =
              element.programacao_fornecedor
            nova_estrutura.tipo_frete = elem
            nova_estrutura.cliente = element.cliente
            nova_estrutura.pedidos = element.pedidos
            nova_estrutura.status = [
              {
                descricao: 'Cotas Liberadas',
              },
              {
                descricao: 'Agendados',
              },
              {
                descricao: 'Atendidas',
              },
              {
                descricao: 'Disponível',
              },
              {
                descricao: 'Performace',
              },
            ]
            i = estrutura.push(nova_estrutura) - 1
          }
          let nova_data = new Date(
            element.programacao_fornecedor.programacao_unidade.data_inicio
          )
          let new_data = nova_data.toISOString().split('T')[0].split('-')
          let data = new_data.reverse().join('/')
          // Cotas Liberadas
          estrutura[i].status[0]
          estrutura[i].status[0][data] = estrutura[i].status[0][data] || {
            qt_peso: 0,
            qt_caminhoes: 0,
          }
          estrutura[i].status[0][data].qt_peso += element.qt_peso * 1 || 0
          estrutura[i].status[0][data].qt_caminhoes +=
            element.qt_caminhoes * 1 || 0
          // Em trânsito
          estrutura[i].status[1]
          estrutura[i].status[1][data] = estrutura[i].status[1][data] || {
            qt_peso: 0,
            qt_caminhoes: 0,
          }
          estrutura[i].status[1][data].qt_peso +=
            scope.quantidade_agendada_peso(element) || 0
          estrutura[i].status[1][data].qt_caminhoes +=
            scope.quantidade_agendada_caminhoes(element) || 0
          // Na triagem
          estrutura[i].status[2]
          estrutura[i].status[2][data] = estrutura[i].status[2][data] || {
            qt_peso: 0,
            qt_caminhoes: 0,
          }
          estrutura[i].status[2][data].qt_caminhoes +=
            scope.quantidade_triagem_caminhoes(element) || 0
          // Atendidas
          estrutura[i].status[4]
          estrutura[i].status[4][data] = estrutura[i].status[4][data] || {
            qt_peso: 0,
            qt_caminhoes: 0,
          }
          estrutura[i].status[4][data].qt_caminhoes =
            scope.performace_caminhoes(element) || '0,00 %'
          // Disponível
          estrutura[i].status[3]
          estrutura[i].status[3][data] = estrutura[i].status[3][data] || {
            qt_peso: 0,
            qt_caminhoes: 0,
          }
          estrutura[i].status[3][data].qt_peso +=
            scope.quantidade_restante_peso(element) || 0
          estrutura[i].status[3][data].qt_caminhoes +=
            scope.quantidade_restante_caminhoes(element) || 0
        })
      })
      this.programacoesRegiaoEstruturada = estrutura
    },
    changeHeadersDay(newHeadersDay) {
      this.headersDay = [this.headersDay[0]].concat(newHeadersDay)
    },
  },
}
</script>
